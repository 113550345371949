import React, { Fragment } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

const PrintContainer = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  text-align: center;
  grid-gap: 20px;
  .imgContainer {
    position: relative;
    min-height: 270px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .gatsby-image-wrapper {
    position: initial !important;
  }
  h2 {
    font-size: 1.4rem;
    color: #339966;
    margin-bottom: 0.8rem;
  }
  a {
    text-decoration: none;
    color: #333;
  }
`

const Prints = ({ artwork, imgType, series }) => (
  <PrintContainer>
    {artwork.map(art => (
      <Fragment key={art.node.id}>
        {art.node.acf.image_type.includes(imgType) && art.node.acf.series.includes(series) && (
          <article>
            <a href={art.node.acf.etsy_link} target="_blank" rel="noopener noreferrer">
              <div className={imgType + ' imgContainer art'} key={art.node.featured_media.id}>
                <Img
                  fluid={art.node.featured_media.localFile.childImageSharp.fluid}
                  alt={art.node.title}
                  key={art.node.featured_media.wordpress_id}
                />
              </div>
              <h2>“{art.node.title}”</h2>
              {art.node.acf.prints_description ? (
                <p className="description">{art.node.acf.prints_description}</p>
              ) : (
                <p className="description">{art.node.acf.description}</p>
              )}
            </a>
          </article>
        )}
      </Fragment>
    ))}
  </PrintContainer>
)

export default Prints
